/**
 * Header
 */

.tm-header {
    box-shadow: 0 0 5px rgba(#000000, 0.15);
    position: relative;
    z-index: 99;

    &-toparea {
        font-size: 14px;
        padding: 7px 0;
        position: relative;
        z-index: 100;
    }

    &-options {
        text-align: right;
        padding: 3px 0;
    }

    &-links,
    &-currency,
    &-language {
        display: inline-block;
        position: relative;
        text-align: left;
        z-index: 2;

        &:not(:last-child) {
            margin-right: 30px;
        }

        &>a,
        &>button {
            padding: 0;
            border: none;
            color: #ffffff;
            height: auto;

            &:after {
                content: "\f123";
                font-family: 'Ionicons';
                font-size: 10px;
                margin-left: 5px;
            }

            &:focus {
                outline: none;
            }
        }

        ul {
            display: none;
            position: absolute;
            width: 100%;
            min-width: 180px;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            background: $color-grey;
            box-shadow: $shadow;
            left: auto;
            top: 100%;
            right: 0;
            padding: 0 20px;
            border-bottom: 3px solid $color-theme;
            z-index: 9;

            li {
                color: $color-body;
                border-bottom: 1px solid $color-border;
                transition: $transition;
                display: block;

                a {
                    display: block;
                    padding: 5px 0;
                    color: $color-body;

                    &:hover {
                        color: $color-theme;
                    }
                }

                &:hover {
                    color: $color-theme;
                }
            }
        }

        li,
        button {
            img {
                margin-right: 10px;
            }
        }
    }

    &-info {
        padding: 3px 0;
        margin-bottom: 0;
        font-size: 0;

        li {
            display: inline-block;
            margin-right: 30px;
            list-style: none;
            font-size: 15px;
            line-height: 26px;
            vertical-align: middle;
            color: #ffffff;

            i {
                margin-right: 8px;
                font-size: 18px;
                line-height: 1;
                vertical-align: text-bottom;
            }

            a {
                display: inline-block;
                vertical-align: middle;
                color: #ffffff;

                &:hover {
                    color: $color-theme;
                }
            }
        }
    }

    &-middlearea {
        padding: 25px 0;
        position: relative;
        z-index: 7;
    }

    &-logo {
        display: inline-block;
        height: 46px;

        img {
            max-height: 100%;
        }
    }

    &-search {
        position: relative;

        input:not([type="submit"]) {
            border-radius: 100px;
            padding-left: 20px;
            padding-right: 75px;
            @include placeholder-color(#959595);
        }

        button,
        input[type="submit"] {
            position: absolute;
            left: auto;
            right: 0;
            top: 0;
            border-radius: 0px 100px 100px 0;
            font-size: 24px;
            background: $color-heading;
            color: #ffffff;
            border-color: $color-heading;
            width: 60px;
            text-align: center;
            padding: 0;

            &:hover {
                background: $color-theme;
                border-color: $color-theme;
            }
        }
    }

    &-icons {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        text-align: right;

        li {
            display: inline-block;

            &:not(:last-child) {
                margin-right: 30px;
            }

            a {
                font-size: 24px;
                color: $color-black;
                position: relative;

                span {
                    height: 15px;
                    width: 15px;
                    border-radius: 100px;
                    line-height: 15px;
                    position: absolute;
                    top: auto;
                    bottom: 0;
                    left: auto;
                    right: -10px;
                    font-size: 11px;
                    background: $color-theme;
                    color: #ffffff;
                    text-align: center;
                }

                &:hover {
                    color: $color-theme;
                }
            }
        }
    }

    &-bottomarea {
        border-top: 1px solid $color-border;
    }

    &-nav {
        text-align: center;
    }

    @media #{$layout-tablet} {
        &-options {
            text-align: center;
        }

        &-search {
            margin-top: 20px;
        }

        &-info {
            text-align: center;
        }

        &-icons {
            padding-right: 55px;
        }
    }

    @media #{$layout-mobile-lg} {
        &-options {
            text-align: center;
        }

        &-links {
            ul {
                right: auto;
                left: 0;
            }
        }


        &-currency {
            ul {
                left: 50%;
                right: auto;
                transform: translateX(-50%);
            }
        }

        &-search {
            margin-top: 20px;
        }

        &-info {
            text-align: center;
        }

        &-icons {
            padding-right: 45px;

            li:not(:last-child) {
                margin-right: 20px;
            }
        }

        &-logo {
            height: 33px;
        }
    }
}

.tm-header-sticky {
    .tm-header-bottomarea {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        top: 100%;
        z-index: 99;
        box-shadow: 0 2px 2px rgba(#000000, 0.11);
    }

    &.is-sticky{
        .tm-header-bottomarea {
            position: fixed;
            top: 0;
            animation: slideInDown .5s ease-in-out 0s 1 both;
        }
    }
}


/* Mobilemenu */
.tm-mobilenav {
    position: relative;
    top: 100%;
    width: 100%;

    .mean-bar {
        background: #0c1923;
        padding: 0;
        min-height: 0;
        z-index: 99;
    }

    a.meanmenu-reveal {
        margin-top: 8px;
        text-indent: 0;
        padding: 0;
        text-align: center;
        width: 25px;
        height: 25px;
        line-height: 20px;
        color: #3c424f;
        font-size: 24px !important;
    }

    .mean-nav {
        margin-top: 66px;
        background: $color-grey;
        position: absolute;
        left: 0;
        top: 100%;
        right: 0;
        width: 100%;
        box-shadow: $shadow;

        &>ul {
            max-height: 205px;
            overflow-y: auto;

            li {
                a {
                    padding: 7px 5%;
                    border-top: 1px solid rgba(170, 170, 170, 0.2);
                    font-size: 14px;
                    font-family: $ff-heading;
                    text-transform: inherit;
                    color: $color-heading;

                    &:hover {
                        background: $color-theme;
                        color: #ffffff;
                    }

                    &.mean-expand {
                        padding: 0 6px !important;
                        height: 41px;
                        line-height: 41px;
                        background: rgba($color-theme, 0.7);
                        color: #ffffff;
                        margin-top: 0;

                        &:hover {
                            background: rgba(255, 255, 255, .1);
                        }
                    }
                }

                li {
                    a {
                        opacity: 1;
                    }
                }

                ul {
                    li {
                        a {
                            padding: 7px 10%;
                        }
                    }

                    ul {
                        li {
                            a {
                                padding: 7px 15%;
                            }
                        }

                        ul {
                            li {
                                a {
                                    padding: 7px 20%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media #{$layout-mobile-lg} {
        a.meanmenu-reveal {
            margin-top: 3px;
        }

        .mean-nav {
            margin-top: 53px;
        }
    }
}